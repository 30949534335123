@import 'styles/theme-colors';

@mixin OptionsCard {
	position: sticky;
	top: 1rem;

	.dropdown {
		.dropdown-menu {
			.dropdown-item {
				&:focus {
					color: black;
					background: white;
				}

				&.active {
					background: white;
					color: theme-color("primary");
				}

				&:hover {
					background: theme-color("gray-100");
				}
			}
		}
	}
}

.FundingSection {
	.OptionsCard {
		@include OptionsCard;
	}
}
