@import 'styles/theme-colors';
// @import "~bootstrap/scss/bootstrap-grid";
// @include media-breakpoint-down(lg) {

// }

@mixin ConfigCard {
	position: sticky;
	top: 16px;
}

@mixin AnalyzeRow {
	.interval-divider {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: .25rem 0 .5rem;

		.line {
			width: 100%;
			height: 1px;
			background-color: theme-color("gray-400");

			&.line-left {
				margin-right: 1rem;
			}

			&.line-right {
				margin-left: 1rem;
			}
		}

		.interval {
			font-size: 1rem;
			font-weight: 500;
			color: theme-color("gray-800");
		}
	}

	&:first-of-type {
		.interval-divider {
			margin-top: 0;
		}
	}
}

@mixin WidgetsGrid {
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(6, minmax(180px, 1fr));
	overflow-x: scroll;
	padding-bottom: .25rem;
}

@mixin Widget {
	display: grid;
	grid-template-rows: 22px 102px repeat(3, 22px);
	align-items: center;

	.card-header {
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 .5rem;

		.title {
			font-size: .95rem;
			font-weight: 500;
		}

		.icon {
			width: 18px;
			font-size: .85rem;
			text-align: center;
		}
	}

	.card-body {
		padding: 0;

		.Chart {
			.highcharts-credits {
				display: none;
			}
		}
	}

	.card-footer {
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 .5rem;

		.recommendation-label {
			font-size: .8rem;
			font-weight: 500;
			color: theme-color("gray-700");
			text-transform: capitalize;
		}

		.icon {
			width: 18px;
			font-size: .85rem;
			text-align: center;
		}
	}
}

.Analyze {
	.ConfigCard {
		@include ConfigCard;
	}

	.AnalyzeRows {
		.AnalyzeRow {
			@include AnalyzeRow;

			.WidgetsGrid {
				@include WidgetsGrid;

				.Widget {
					@include Widget;
				}
			}
		}
	}
}
