@mixin PastOrders {
  tbody {
    tr {
      td {
        font-size: 0.75rem;
        white-space: nowrap;
      }
    }
  }
}

.Orders {
}

table.PastOrders {
  @include PastOrders;
}