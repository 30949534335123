@charset "utf-8";

// COLORS
@import "./theme-colors";

// TEXT
$font-family-sans-serif: DM Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$grid-gutter-width: 12px;

$enable-gradients: true;

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
// @import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");

// Custom
@import "./custom";

#root {
  background-color: theme-color("light");

  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }

  .pointer {
    cursor: pointer;
  }

  .w-fit {
    width: fit-content;
    max-width: 100%;
  }
}

html > body > iframe { display: none; }

.noscroll {
  overflow: hidden;
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-y {
  overflow-y: scroll;
}
