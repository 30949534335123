@import 'styles/theme-colors';

.PositionsTable-Mobile {
	.item-wrapper {
		&:not(:first-child) {
			margin-top: .75rem;
		}

		> .Flex {
			&:nth-child(even) {
				text-align: right;
			}
		}
	}

	.label {
		font-size: 0.8rem;
		font-weight: 500;
		color: theme-color("gray-600");
		text-transform: uppercase;
		margin-bottom: .15rem;
	}

	.value {
		font-size: 1.1rem;
	}
}
