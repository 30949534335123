@import 'styles/theme-colors';
@import 'styles/custom/scrollbar';

.ToggleLabel {
	transition: color 0.15s ease;
}

.Toggle {
	background-color: theme-color("gray-200");
	border-radius: 6px;
	padding: 2px;
	border: 1px solid theme-color("gray-300");
	transition: 0.15s color ease, 0.15s background-color ease, 0.15s opacity ease, border ease, 0.15s opacity ease;
	user-select: none;
	@include hideScrollbar;

	.Option {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		background-color: theme-color("gray-200");
		color: theme-color("gray-600");
		border-radius: 4px;
		padding: 1px .25rem;
		text-align: center;

		text-transform: capitalize;
		white-space: nowrap;
		cursor: pointer;
		user-select: none;

		transition: background-color 0.15s ease, color 0.15s ease;

		&:not(.disabled):hover {
			background-color: theme-color("gray-100");
		}

		// &:not(:last-child) {
		// 	margin-right: .15rem;
		// }

		&.active {
			background-color: white;
			color: theme-color("gray-800");
		}

		&.disabled {
			cursor: not-allowed;
			opacity: 0.6;
		}

		&.loading {
			background-color: theme-color("gray-100");
			color: theme-color("gray-400");
		}

		.Loader {
			position: absolute;
			color: theme-color("gray-600");
		}
	}
}
