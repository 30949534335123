@import 'styles/theme-colors';

.PlAtPriceCalculator {
	.RangeDivider {
		background-color: theme-color('gray-500');
		position: absolute;
		bottom: 11px;
		width: 6px;
		height: 8px;
		transition: background-color .15s ease;
		cursor: pointer;

		&:hover {
			background-color: theme-color('primary');
		}
	}
}
