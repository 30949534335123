@import 'styles/theme-colors';

@mixin boxShadowOnHover {
	@media only screen and (min-width : 992px) {
		&:hover {
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
		}
	}
}

@mixin AmountInput {
	.AmountInput {
		input {
			&:focus {
				box-shadow: none;
			}
		}
	}
}

@mixin CloseRangeSlider {
	&::-webkit-slider-thumb {
	  background: theme-color("danger") !important;
	}

	&::-moz-range-thumb {
	  background: theme-color("danger") !important;
	}

	&::-ms-thumb {
	  background: theme-color("danger") !important;
	}
}

@mixin UtilsCard {
	.UtilsCard {
		.BulkCloseRangeSlider {
			height: 1rem;

			@include CloseRangeSlider;
		}

		.reset-to-default-amounts-icon {
			font-size: 14px;
			cursor: pointer;
			padding-bottom: .5rem;
			padding-left: .5rem;
			color: theme-color("gray-700");

			&:hover {
				color: theme-color("primary");
			}
		}
	}
}

@mixin PositionRow {
	.PositionRow {
		padding: .25rem 0;
		border-radius: .25rem;
		cursor: pointer;

		@include boxShadowOnHover;

		@media only screen and (max-width : 991px) {
		 	padding: .25rem 0 .5rem;
		}

		.PositionCloseRangeSlider {
			height: 35px;
			@include CloseRangeSlider;
		}
	}
}

@mixin NewPairTradePopup {
	.NewPairTradePopup {
		.ContractRow {
			padding: .35rem .5rem;
			border-radius: .25rem;
			cursor: pointer;
			font-size: 1rem;

			@include boxShadowOnHover;

			&:hover {
				color: theme-color("primary");
			}

			.FavoriteIcon {
				font-size: .9rem;
				margin-top: -2px;
				margin-right: .25rem;
				color: theme-color("warning-light");
			}
		}
	}
}

.BulkSection {
	@include AmountInput;
	@include UtilsCard;
	@include PositionRow;

	.custom-checkbox {
		cursor: pointer;

		input, label {
			cursor: pointer;
		}
	}

	.UtilsWrapper {
		flex-direction: column-reverse;

		@media only screen and (max-width : 991px) {
			flex-direction: row;
			overflow-x: scroll;

			> * {
				min-width: 100%;

				&:first-child {
					margin-right: .5rem;
				}
			}
		}

		@media only screen and (min-width : 992px) {
  		position: sticky;
  		top: 1rem;
		}
	}
}

@include NewPairTradePopup;
