@import 'styles/theme-colors';

@mixin SymbolsList {
	.SymbolsList {
		.item {
			cursor: pointer;
			border-radius: .25rem;

			will-change: background-color, color;
			transition: 0.2s ease background-color, 0.2s ease color;

			&:hover {
				background-color: theme-color("gray-100");
			}

			&.active {
				color: theme-color("primary");
			}
		}
	}
}

.FirechartSection {
	@include SymbolsList;
}
