@import 'styles/theme-colors';

.CryptoIcon {
	border-radius: 50%;
	margin-top: -1px;

	&.show-shadow {
		border: 1px solid theme-color("gray-400");
		box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.25) !important;
	}
}
