.font-weight-regular {
	font-weight: 500;
}

@for $i from 0 through 9 {
	.f-rem-0\.#{$i} {
		font-size: calc(#{$i} * .1rem) !important;
	}

	.f-rem-0\.#{$i}25 {
		font-size: calc(#{$i} * .1rem + .025rem) !important;
	}

	.f-rem-0\.#{$i}5 {
		font-size: calc(#{$i} * .1rem + .05rem) !important;
	}

	.f-rem-0\.#{$i}75 {
		font-size: calc(#{$i} * .1rem + .075rem) !important;
	}

	.f-rem-1\.#{$i} {
		font-size: calc(#{$i} * .1rem + 1rem) !important;
	}

	.f-rem-1\.#{$i}25 {
		font-size: calc(#{$i} * .1rem + 1rem + .025rem) !important;
	}

	.f-rem-1\.#{$i}5 {
		font-size: calc(#{$i} * .1rem + 1rem + .05rem) !important;
	}

	.f-rem-1\.#{$i}75 {
		font-size: calc(#{$i} * .1rem + 1rem + .075rem) !important;
	}

	.f-rem-2\.#{$i} {
		font-size: calc(#{$i} * .1rem + 2rem) !important;
	}

	.f-rem-2\.#{$i}25 {
		font-size: calc(#{$i} * .1rem + 2rem + .025rem) !important;
	}

	.f-rem-2\.#{$i}5 {
		font-size: calc(#{$i} * .1rem + 2rem + .05rem) !important;
	}

	.f-rem-2\.#{$i}75 {
		font-size: calc(#{$i} * .1rem + 2rem + .075rem) !important;
	}

	.fw-#{$i} {
		font-weight: $i * 100 !important;
	}

	.fw#{$i} {
		font-weight: $i * 100 !important;
	}
}

@for $i from 1 to 50 {
	.f-rem-#{$i} {
		font-size: #{$i}rem !important;
	}

	.f-px-#{$i} {
		font-size: calc(#{$i} * 1px) !important;
	}
}
