@mixin hideScrollbar {
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		display: none;
		width: 0 !important;
		height: 0 !important;
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
	  border-radius: 0px;
	  background-color: rgba(0,0,0,0);
	  -webkit-box-shadow: none;
	  border: none;
	}

	&::-webkit-scrollbar-track {
	  background-color: none;
	  -webkit-box-shadow: none;
	}

	-ms-overflow-style: none; /* IE 11 */
	overflow: -moz-scrollbars-none;
	scrollbar-width: thin; /* Firefox 64 */
	scrollbar-color: transparent transparent;
}
