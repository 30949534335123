.input-group {
	.input-group-text {
		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right: none;
		}

		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-left: none;
		}
	}
}
