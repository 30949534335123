@each $colorClass, $color in $theme-colors {
	// Lighten
	.text-#{$colorClass} {
		&.text-lighten,
		&.text-lighten-5 {
			color: lighten($color, 5%) !important;
		}

		&.text-lighten-10 {
			color: lighten($color, 10%) !important;
		}

		&.text-lighten-15 {
			color: lighten($color, 15%) !important;
		}

		&.text-lighten-20 {
			color: lighten($color, 20%) !important;
		}

		&.text-lighten-25 {
			color: lighten($color, 25%) !important;
		}

		&.text-lighten-30 {
			color: lighten($color, 30%) !important;
		}

		&.text-lighten-35 {
			color: lighten($color, 35%) !important;
		}

		&.text-lighten-40 {
			color: lighten($color, 40%) !important;
		}

		&.text-lighten-45 {
			color: lighten($color, 45%) !important;
		}

		&.text-lighten-50 {
			color: lighten($color, 50%) !important;
		}
	}

	// Darken
	.text-#{$colorClass} {
		&.text-darken,
		&.text-darken-5 {
			color: darken($color, 5%) !important;
		}

		&.text-darken-10 {
			color: darken($color, 10%) !important;
		}

		&.text-darken-15 {
			color: darken($color, 15%) !important;
		}

		&.text-darken-20 {
			color: darken($color, 20%) !important;
		}

		&.text-darken-25 {
			color: darken($color, 25%) !important;
		}

		&.text-darken-30 {
			color: darken($color, 30%) !important;
		}

		&.text-darken-35 {
			color: darken($color, 35%) !important;
		}

		&.text-darken-40 {
			color: darken($color, 40%) !important;
		}

		&.text-darken-45 {
			color: darken($color, 45%) !important;
		}

		&.text-darken-50 {
			color: darken($color, 50%) !important;
		}
	}

	// Hvr lighten
	.text-#{$colorClass}:hover {
		transition: color .15s ease;

		&.hvr-text-lighten,
		&.hvr-text-lighten-5 {
			color: lighten($color, 5%) !important;
		}

		&.hvr-text-lighten-10 {
			color: lighten($color, 10%) !important;
		}

		&.hvr-text-lighten-15 {
			color: lighten($color, 15%) !important;
		}

		&.hvr-text-lighten-20 {
			color: lighten($color, 20%) !important;
		}

		&.hvr-text-lighten-25 {
			color: lighten($color, 25%) !important;
		}

		&.hvr-text-lighten-30 {
			color: lighten($color, 30%) !important;
		}

		&.hvr-text-lighten-35 {
			color: lighten($color, 35%) !important;
		}

		&.hvr-text-lighten-40 {
			color: lighten($color, 40%) !important;
		}

		&.hvr-text-lighten-45 {
			color: lighten($color, 45%) !important;
		}

		&.hvr-text-lighten-50 {
			color: lighten($color, 50%) !important;
		}
	}

	// Hvr darken
	.text-#{$colorClass}:hover {
		transition: color .15s ease;

		&.hvr-text-darken,
		&.hvr-text-darken-5 {
			color: darken($color, 5%) !important;
		}

		&.hvr-text-darken-10 {
			color: darken($color, 10%) !important;
		}

		&.hvr-text-darken-15 {
			color: darken($color, 15%) !important;
		}

		&.hvr-text-darken-20 {
			color: darken($color, 20%) !important;
		}

		&.hvr-text-darken-25 {
			color: darken($color, 25%) !important;
		}

		&.hvr-text-darken-30 {
			color: darken($color, 30%) !important;
		}

		&.hvr-text-darken-35 {
			color: darken($color, 35%) !important;
		}

		&.hvr-text-darken-40 {
			color: darken($color, 40%) !important;
		}

		&.hvr-text-darken-45 {
			color: darken($color, 45%) !important;
		}

		&.hvr-text-darken-50 {
			color: darken($color, 50%) !important;
		}
	}
}

.text-color-transition {
	transition: color .15s ease;
}
