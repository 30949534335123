@import 'styles/theme-colors';
@import 'styles/custom/scrollbar';

@mixin sideScrollOnMobile {
	@media only screen and (max-width : 991px) {
		display: flex;
		flex-direction: row;
		overflow-x: scroll;
		padding-bottom: .5rem;

		> * {
			min-width: 100%;
			margin-top: 0;

			&:not(:first-child) {
				margin-left: .5rem;
			}

			&.d-none + *:not(.d-none) {
				margin-left: 0;
			}
		}
	}
}

@mixin PositionSection {

}

@mixin NewOrder {
	&.d-none + * {
		margin-top: 0;
	}

	.form-label {
		font-size: 0.8rem;
		font-weight: 500;
		color: theme-color('gray-700');
		margin-bottom: 0.25rem;
	}

	.form-group {
		input {
			font-size: 0.85rem;
		}
	}
}

@mixin QuickOpen {
	margin-top: .75rem;
}

@mixin QuickClose {
	margin-top: .75rem;
}

@mixin OpenOrders {
	margin-top: .75rem;
	user-select: none;
}

@mixin CloseOrders {
	margin-top: .75rem;
	user-select: none;

	.close-profit-wrapper {
		.profit-on-close {
			display: block;
		}

		.total-profit {
			display: none;
		}

		&:hover {
			.profit-on-close {
				display: none;
			}

			.total-profit {
				display: block;
			}
		}
	}
}

@mixin OrdersTable {
	thead {
		tr {
			th {
				padding: 0.3rem 1rem;
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 0.3rem 1rem;
			}
		}
	}

	.OrderRow {
		&.mouseEnterPriceline {
			background-color: theme-color('gray-200');
		}

		&.isTentativeOrder {
			background-color: theme-color('gray-300');
		}
	}
}

@mixin PositionSummary {
	.item-wrapper {
		flex-direction: column;

		&:not(:first-child) {
			margin-top: .75rem;
		}

		.label {
			font-size: 0.75rem;
			font-weight: 500;
			color: theme-color("gray-600");
		}

		.value {
			font-size: 0.95rem;
			font-weight: 500;
		}
	}
}

@mixin SwitchPositionPopup {
	input.search {
		border-radius: 0px;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid theme-color("gray-200");

		&:focus {
			box-shadow: none;
			border-bottom: 2px solid theme-color("gray-200");
		}
	}

	.SwitchPositionRow {
		padding: 0.35rem 0.75rem;
		cursor: pointer;
		transition: 0.15s ease background-color;
		will-change: background-color;
		border-bottom: 1px solid theme-color("gray-200");

		&:hover {
			background-color: theme-color("gray-200");
		}

		.symbol-container {
			.CryptoIcon {
				width: 22px;
				height: 22px;
				margin-right: .25rem;;
			}

			.symbol {
				font-size: 1.15rem;
				font-weight: 500;
			}

			.positionSide {
				font-size: 0.8rem;
				font-weight: 700;
			}
		}

		.value-profit-container {
			.positionValue {
				font-size: 1rem;
				font-weight: 500;
				color: theme-color("gray-700");
			}

			.profit-container {
				font-size: 0.85rem;
				font-weight: 500;

				.realisedProfit {

				}

				.unrealizedProfit {

				}
			}
		}
	}
}

.PositionSection {
	@include PositionSection;

	.PositionSummary {
		@include PositionSummary;
	}

	.NewOrder {
		@include NewOrder;
	}

	.QuickOpen {
		@include QuickOpen;
	}

	.QuickClose {
		@include QuickClose;
	}

	.OpenOrders {
		@include OpenOrders;
	}

	.CloseOrders {
		@include CloseOrders;
	}

	.OrdersTable {
		@include OrdersTable;
	}

	.SwitchPositionPopup {
		@include SwitchPositionPopup;
	}

	.PositionSummary-QuickOpen-QuickClose-wrapper {
		@include hideScrollbar;
		@include sideScrollOnMobile;

		@media only screen and (min-width : 992px) {
			flex-direction: column;
  		position: sticky;
  		top: 1rem;
		}
	}

	.NewOrder-OpenOrders-CloseOrders-wrapper {
		@include hideScrollbar;
		@include sideScrollOnMobile;

		@media only screen and (min-width : 992px) {
			flex-direction: column;
		}
	}
}
