@import 'styles/theme-colors';

@mixin Options {
	.Options {

	}
}

@mixin OrderbookBarChart {
	.OrderbookBarChart {
		.row-wrapper {
			position: relative;

			.bar {
				position: absolute;
				right: 0;
				height: 100%;
				z-index: 0;

				will-change: width;
				transition: width 0.1s ease-out;
			}

			.price,
			.qty {
				z-index: 1;
			}

			.price {
				&.largest-qty {
					font-weight: 600;
				}
			}
		}
	}
}

@mixin OrderbookChart {
	.currentPrice {
		font-size: 1.3rem;

		.price {
			font-weight: 500;
		}

		.icon {
			margin-left: .35rem;
			margin-top: 0.2rem;
			color: theme-color("gray-300");

			will-change: color, transform;
			transition: 0.1s ease color, 0.1s ease transform;

			&.down {
				color: theme-color("danger");
				margin-top: -0.1rem;
			}

			&.up {
				color: theme-color("success");
			}
		}
	}
}

.OrderbookChart {
	@include OrderbookChart;
	@include Options;
	@include OrderbookBarChart;
}
